
import useVuelidate from "@vuelidate/core";
import { email } from "@vuelidate/validators";
import { computed, defineComponent, ref, watch, PropType } from "vue";
import FormErrors from "@/forms/FormErrors.vue";
import {
  RoleAssigneeType,
  UserRoleType,
  UserType,
  OrganizationType,
  RoleType,
} from "@/graphql/types";
import { useQuery, useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import RoleOnBehalfOfPicker from "./pickers/RoleOnBehalfOfPicker.vue";
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import useRemoveUserRole from "@/views/User/mutations/useRemoveUserRole";
import useAddUserRoleOnBehalfOf from "./mutaions/useAddUserRoleOnBehalfOf";
import useToastService from "@/composables/useToastService";
import useError from "@/composables/useError";
import Error from "@/components/Error.vue";

const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      userId
      email
      firstname
      lastname
      organization {
        name
        cvr
      }
      roles {
        roleId
        organizationId
        applicationId
        roleName
        organizationName
        applicationName
      }
    }
  }
`;

export default defineComponent({
  props: {
    organization: {
      type: Object as PropType<OrganizationType | null>,
      default: null,
    },
  },
  components: { FormErrors, RoleOnBehalfOfPicker, Error },
  setup(props) {
    const toastService = useToastService();
    const roleOnBehalfOfPickerPicker = ref<VDPopupListboxClass>();
    const isVisible = ref(false);
    const emailField = ref<string>("");
    const userIdAssigningRole = ref<string | null>(null);
    const userAssigningRole = ref<UserType>({ roles: [] });
    const v$ = useVuelidate(useValidations(), { emailField: emailField });
    const queryVariables = ref({});
    const queryOptions = ref();

    const addUserRoleOnBehalfOf = useAddUserRoleOnBehalfOf();
    const removeUserRole = useRemoveUserRole();

    queryOptions.value = {
      enabled: false,
      fetchPolicy: "network-only",
    };

    watch(emailField, (newValue) => {
      queryVariables.value = { email: newValue };
      queryOptions.value = {
        enabled: !v$.value.$invalid && emailField.value !== "",
        fetchPolicy: "network-only",
      };
    });

    function reset() {
      emailField.value = "";
      userIdAssigningRole.value = null;
      userAssigningRole.value = {};
      v$.value.$reset();
    }

    const {
      result: resultGetUserOfEmail,
      loading: loadingGetUserOfEmail,
      onError: onErrorGetUserOfEmail,
    } = useQuery(GET_USER_BY_EMAIL, queryVariables, queryOptions);

    onErrorGetUserOfEmail((error) => {
      toastService.show({
        severity: "error",
        title: "Error",
        message: error.message,
      });
    });

    addUserRoleOnBehalfOf.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Tildel rolle",
        message: "Rolle tildelt med succes",
      });
    });

    removeUserRole.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Fjern rolle",
        message: "Rolle fjernet med succes",
      });
    });

    function handleInput() {
      v$.value.$touch();
    }

    watch(
      () => useResult(resultGetUserOfEmail).value,
      (newValue) => {
        if (newValue) {
          userIdAssigningRole.value = newValue.userId;
          Object.assign(userAssigningRole.value, newValue);
        } else {
          userIdAssigningRole.value = null;
        }
      },
      { immediate: true }
    );

    function onRemovedRole(role: RoleAssigneeType) {
      function remove() {
        userAssigningRole.value.roles =
          userAssigningRole.value.roles?.filter(
            (element) =>
              element?.roleId != role.roleId ||
              element?.organizationId != role.organizationId
          ) ?? [];
      }

      removeUserRole
        .call(
          role.roleId ?? "",
          userAssigningRole.value.userId ?? "",
          role.organizationId ?? ""
        )
        .then((value) => {
          if (value != null) {
            remove();
          }
        });
    }

    function onAddedRole(role: UserRoleType) {
      function add() {
        userAssigningRole.value.roles = [
          ...(userAssigningRole.value.roles ?? []),
          {
            roleId: role.roleId,
            roleName: role.roleName,
            organizationId: props?.organization?.organizationId,
            organizationName: props?.organization?.name,
            applicationId: role?.applicationId,
            applicationName: role?.applicationName,
          },
        ];
      }

      addUserRoleOnBehalfOf
        .call(
          emailField.value,
          role.roleId ?? "",
          props?.organization?.organizationId ?? ""
        )
        .then((value) => {
          if (value != null) {
            add();
          }
        });
    }

    return {
      isVisible,
      v$,
      isLoading: false,
      show: () => {
        isVisible.value = true;
        reset();
      },
      hide: () => {
        isVisible.value = false;
        reset();
      },
      emailField,
      handleInput,
      roleOnBehalfOfPickerPicker,
      addRemoveLoading: computed(
        () =>
          userIdAssigningRole.value === null ||
          loadingGetUserOfEmail.value ||
          addUserRoleOnBehalfOf.loading.value ||
          removeUserRole.loading.value
      ),
      userAssigningRole,
      onRemovedRole,
      userIdAssigningRole,
      onAddedRole,
    };
  },
});

function useValidations() {
  return {
    emailField: { email },
  };
}
