
import AppHeader from "@/components/AppHeader.vue";
import Error from "@/components/Error.vue";
import VDModal from "@/components/modals/VDModal";
import Page from "@/components/Page.vue";
import OrganizationModal from "@/components/modals/OrganizationModal/OrganizationModal.vue";
import AssignUserRoleOnBehalfOfModal from "@/components/modals/AssignUserRoleOnBehalfOfModal/AssignUserRoleOnBehalfOfModal.vue";
import useError from "@/composables/useError";
import {
  OrganizationType,
  PageParamsInputType,
  useGetOrganizationQuery,
  UserType,
  AssignUserRoleOnBehalfOfInputfType,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { FilterMatchMode } from "primevue/api";
import { defineComponent, ref, watch, computed } from "vue";
import useUser from "@/composables/useUser";
import { useRoute } from "vue-router";
import { ApprovalStateEnum } from "@/models/Enum";
import { formatDate } from "@/utils/date";
import Pagination from "@/components/pagination/Pagination.vue";
import { PageState } from "primevue/paginator";

// Query
gql`
  query GetOrganization(
    $organizationId: ID
    $pageParams: PageParamsInputType!
  ) {
    organization(organizationId: $organizationId) {
      name
      organizationId
      cvr
      type
      users(pageParams: $pageParams) {
        total
        items {
          roles {
            ...UserRoleParts
          }
          ...UserParts
        }
      }
      assigneeRoles {
        roleId
        roleName
        applicationId
        applicationName
        applicationName
        organizationId
        organizationName
      }
    }
  }
`;

export default defineComponent({
  inheritAttrs: false,
  components: {
    AppHeader,
    Page,
    Error,
    Pagination,
    OrganizationModal,
    AssignUserRoleOnBehalfOfModal,
  },
  name: "organization",
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const expandedRows = ref<UserType[]>([]);
    const route = useRoute();
    const { isGlobalAdmin, isLocalAdmin } = useUser();

    const organizationModal = ref<VDModal<OrganizationType> | null>(null);

    const assignUserRoleOnBehalfOfModal =
      ref<VDModal<AssignUserRoleOnBehalfOfInputfType> | null>(null);

    const showOrganizationEditOption = computed<boolean>(
      () => isGlobalAdmin.value || isLocalAdmin.value
    );

    const pageParams = ref<PageParamsInputType>({
      pageSize: 10,
      currentPage: 1,
    });

    const { result, loading, error, refetch } = useGetOrganizationQuery(
      { organizationId: props.organizationId, pageParams: pageParams.value },
      {
        fetchPolicy: "network-only",
      }
    );

    function onPageChange(event: PageState) {
      pageParams.value.pageSize = event.rows;
      pageParams.value.currentPage = event.page + 1;
      refetch({ pageParams: pageParams.value });
    }

    function onExpandClicked(data: UserType) {
      if (expandedRows.value.length > 0 && expandedRows.value[0] == data) {
        expandedRows.value = [];
      } else {
        expandedRows.value = [data];
      }
    }

    watch(
      () => route.params,
      async (newValue) => {
        const hasOnlyOrganizationId =
          Object.keys(newValue).length === 1 &&
          Object.prototype.hasOwnProperty.call(newValue, "organizationId");

        if (!hasOnlyOrganizationId) {
          return;
        }

        try {
          await refetch({
            organizationId: newValue.organizationId.toString(),
            pageParams: pageParams.value,
          });
        } catch (error) {
          console.error(error);
        }
      }
    );

    return {
      organizationModal,
      organization: useResult(result),
      error: useError(error),
      loading,
      filters: useFilters(),
      onExpandClicked,
      expandedRows: expandedRows,
      showOrganizationEditOption,
      ApprovalStateEnum,
      formatDate,
      pageParams,
      onPageChange,
      assignUserRoleOnBehalfOfModal,
    };
  },
});

function useFilters() {
  return ref({
    global: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
  });
}
