
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import VDPopupListbox from "@/components/pickers/VDPopupListbox.vue";
import { RoleAssigneeType, UserRoleType } from "@/graphql/types";
import GroupedItems from "@/models/GroupedItems";
import gql from "graphql-tag";
import { defineComponent, PropType, ref, computed } from "vue";

gql`
  query getRoleAssignees {
    roleAssignees {
      ...RoleAssigneeParts
    }
  }
`;

export default defineComponent({
  components: {
    VDPopupListbox,
  },
  emits: ["addedRole"],
  props: {
    addedRoles: {
      type: Array as PropType<UserRoleType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    assigneeRoles: {
      type: Array as PropType<RoleAssigneeType[]>,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    // VDOM
    const listbox = ref<VDPopupListboxClass>();

    // Use a computed property for roles
    const roles = computed(() =>
      useGroupedRolesByApplication(props.assigneeRoles)
    );

    return {
      roles,
      listbox,
      toggle: (event: Event) => {
        listbox.value?.toggle(event);
      },
    };
  },
});

function useGroupedRolesByApplication(assigneeRoles: RoleAssigneeType[]) {
  const groupedRoles: GroupedItems<RoleAssigneeType>[] = [];

  const map: { [name: string]: RoleAssigneeType[] } = {};

  assigneeRoles?.forEach((element) => {
    if (element?.applicationName && map[element.applicationName]) {
      map[element.applicationName].push(element);
    } else if (element?.applicationName) {
      map[element.applicationName] = [element];
    }
  });

  groupedRoles.push(
    ...Object.keys(map)
      .sort()
      .map((element) => {
        return {
          name: element,
          items: map[element].sort(
            (a, b) => a.roleName?.localeCompare(b.roleName ?? "") ?? -1
          ),
        };
      })
  );

  return groupedRoles;
}
