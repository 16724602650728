import { useAssignUserRoleOnBehalfOfMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation AssignUserRoleOnBehalfOf(
    $input: AssignUserRoleOnBehalfOfInputfType!
  ) {
    assignUserRoleOnBehalfOf(input: $input)
  }
`;

export default function () {
  const mutation = useAssignUserRoleOnBehalfOfMutation({});

  function call(email: string, roleId: string, organizationId: string) {
    return mutation.mutate({
      input: {
        email: email,
        roleId: roleId,
        organizationId: organizationId,
      },
    });
  }
  return {
    ...mutation,
    call,
  };
}
